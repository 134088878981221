import React from 'react';


export const Solution = () => {
    return <svg width="87px" height="82px" viewBox="0 0 317 392" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" fill="none" >
            <g transform="translate(1.525589, 1.072218)">
                <path d="M1.13686838e-13,5 L1.13686838e-13,384 C1.14025015e-13,386.761424 2.23857625,389 5,389 L309,389 C311.761424,389 314,386.761424 314,384 L314,56.9908188 C314,55.7524113 313.540416,54.5580682 312.710259,53.6391058 L289.127319,27.5334078 L289.127319,27.5334078 L265.743641,1.648287 C264.795642,0.598876728 263.447584,5.24843464e-14 262.033383,0 L5,0 C2.23857625,5.07265313e-16 1.13348661e-13,2.23857625 1.13686838e-13,5 Z" stroke="#A48259" strokeWidth="2" fill="#FFFAF6"></path>
                <g transform="translate(60.345570, 50)">
                    <g transform="translate(96.669787, 85.448683)">
                        <polygon fill="#FFE7BF" points="5.45905449 58.1142844 5.45905449 127.138361 66.4109598 92.0995728 66.4109598 22.9722243"></polygon>
                        <polygon fill="#FADAA4" points="74.8878562 12.1686552 95.564342 1.21294221e-16 96.9846433 109.599573 0 164.184559 0 139.511425 75.3340082 97.0995728"></polygon>
                    </g>
                    <g transform="translate(48.399314, 166.841028) scale(-1, 1) translate(-48.399314, -166.841028) translate(0.128841, 84.048813)">
                        <polygon fill="#FAC670" points="5.01535667 59.5141542 5.01535667 128.538231 65.967262 93.4994427 65.967262 24.3720941"></polygon>
                        <polygon fill="#FFBD52" points="73.555835 13.5685251 96.5409455 0 96.5409455 110.999443 0 165.584429 0 140.911294 74.8903104 98.4994427"></polygon>
                    </g>
                    <g transform="translate(96.669787, 83.902985) scale(-1, 1) rotate(240.000000) translate(-96.669787, -83.902985) translate(48.015341, 0.368988)">
                        <polygon fill="#FFBD52" points="5.78330322 58.0861629 5.78330322 127.110239 66.7352085 92.0714514 66.7352085 22.9441028"></polygon>
                        <polygon fill="#FFD58F" points="74.2213955 11.8220343 95.098003 0 97.3088921 109.571451 0.257682065 167.067994 -0.000450092881 140.378042 75.658257 97.0714514"></polygon>
                    </g>
                </g>
                <path d="M264.254639,0 L264.254639,51.2712189 C264.254639,52.908181 265.566889,54.2429434 267.203615,54.270785 L313.017008,55.0500944 C313.562583,55.059375 314,55.5042958 314,56.0499498 L314,61.7657912 L314,61.7657912" stroke="#A48259" strokeWidth="2"></path>
            </g>
        </g>
    </svg>
}